/* eslint-disable react/prop-types */
import React, { ReactElement, forwardRef, useContext, useState, useEffect } from 'react'
import MaterialTable, { MTablePagination } from 'material-table'
import moment from 'moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { Payment } from '../../models/payment'
import { updatePayment, delPayment, addPayment } from '../../services/payment.service'
import { MessageContext } from '../../contexts/message.store'

const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
}
const useStyles = makeStyles((theme: Theme) => ({
  tableSection: {
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
  mTablePagination: {
    width: '300px',
  },
  totalFormat: {
    // alig
  },
  paginationSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))
type TableData = {tableData: {id: number, filterValue: string}}
type FilteredChoice = {column: {field: string, type: string}, operator: string, value: unknown}
type PaginationData = {}
type UserPaymentsProps = {
  payments: Payment[]
  userId: number
  setPayments(payment: Payment[]): void
}
const aggTotal = (acc, payment: Payment): void => acc + +payment.amount

const calcTotal = (filteredPayments: Payment[]): Promise<number> => {
  const count = filteredPayments.reduce(aggTotal, 0)
  return new Promise((resolve) => resolve(count)) // returning as a promise to escape re-rendering the material table when setting the count
}

export default function UserPaymentsTableComponent({ payments, userId, setPayments }: UserPaymentsProps): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [total, setTotal] = useState(0)
  const formattedPayments = payments?.map((payment) => ({ ...payment, payment_date: payment.payment_date })) as Payment[]
  useEffect(() => {
    const initTotal = payments?.reduce(aggTotal, 0)
    setTotal(initTotal)
  }, [payments])
  const getTotal = (): string => {
    return total ? `Total Paid: $${total.toFixed(2)}` : ''
  }


  return (
    <>
      {formattedPayments && (
        <section className={classes.tableSection}>
          <MaterialTable
            icons={tableIcons}
            editable={{
              onRowAdd: (newData): Promise<void> => new Promise((resolve, reject) => {
                addPayment({ ...newData, paid_to_id: userId }).then((payment: Payment) => {
                  payment.payment_date = moment(payment.payment_date).format('M-D-YYYY')
                  setPayments([payment, ...payments])
                  messageDispatch({ type: 'SUCCESS', message: 'Payment Added Successfully' })
                  resolve()
                }).catch(() => {
                  messageDispatch({ type: 'ERROR', message: 'Adding Payment Failed' })
                  reject()
                })
              }),
              onRowUpdate: (newData, oldData: Payment&TableData): Promise<void> => new Promise((resolve, reject) => {
                updatePayment(newData.id, newData).then((success: number) => {
                  if (success) {
                    const dataUpdate = [...payments]
                    const index = oldData.tableData.id
                    dataUpdate[index] = newData
                    setPayments([...dataUpdate])
                    messageDispatch({ type: 'SUCCESS', message: 'Payment Updated Successfully' })
                    resolve()
                  } else reject()
                }).catch(() => {
                  messageDispatch({ type: 'ERROR', message: 'Updating Payment Failed' })
                  reject()
                })
              }),
              onRowDelete: (oldData: Payment&TableData): Promise<void> => new Promise((resolve, reject) => {
                const dataDelete = [...payments]
                const index = oldData.tableData.id
                delPayment(oldData.id).then((success: number) => {
                  if (success) {
                    dataDelete.splice(index, 1)
                    setPayments([...dataDelete])
                    messageDispatch({ type: 'SUCCESS', message: 'Payment Deleted Successfully' })
                    resolve()
                  } else reject()
                }).catch(() => {
                  messageDispatch({ type: 'ERROR', message: 'Deleting Payment Failed' })
                  reject()
                })
              }),
            }}
            columns={[
              { title: 'Paid On',
                field: 'payment_date',
                type: 'date',
                filterComponent: ({ columnDef, onFilterChanged }): ReactElement => {
                  const { tableData } = columnDef as Payment&TableData
                  return (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                    >
                      <DatePicker
                        format="MM/dd/yyyy"
                        value={tableData.filterValue || null}
                        onChange={async (date: Date): Promise<void> => {
                          const filteredDay = moment(date, 'M-D-YYYY').startOf('day')
                          if (date) {
                            const filteredPayments = payments.filter((payment) => {
                              const paymentDay = moment(payment.payment_date, 'M-D-YYYY').startOf('day')
                              return paymentDay.toISOString() == filteredDay.toISOString()
                            })
                            setTotal(await calcTotal(filteredPayments))
                          } else setTotal(await calcTotal(payments))
                          onFilterChanged(`${tableData.id}`, date)
                        }}
                        clearable
                        InputProps={{
                          style: {
                            fontSize: 15,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )
                },

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                editComponent: ({ dateTimePickerLocalization, value, onChange }: any): ReactElement => {
                  const formattedDate = value ? moment(value, 'M-D-YYYY').utc() : Date.now()
                  return (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={dateTimePickerLocalization}
                    >
                      <DatePicker
                        format="MM/dd/yyyy"
                        value={formattedDate}
                        onChange={onChange}
                        clearable
                        InputProps={{
                          style: {
                            fontSize: 15,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )
                },
                render: (row): ReactElement => <>{ moment(row.payment_date, 'M-D-YYYY').format('MM/DD/YYYY') /* formatting code here */ }</>,
              },
              { title: 'Line Item',
                field: 'line_item',
                filtering: false,
              },
              { title: 'Type',
                field: 'payment_type',
                filtering: false,
              },
              { title: 'Amount',
                field: 'amount',
                filtering: false,
              },
              { title: 'Note',
                field: 'notes',
              },
            ]}
            options={{ pageSize: 10, actionsColumnIndex: -1, filtering: true, addRowPosition: 'first' }}
            data={formattedPayments}
            title="Payments"
            components={{
              Pagination: (props): ReactElement => {
                return (
                  <div className={classes.paginationSection}>
                    <span className={classes.totalFormat}>{getTotal()}</span>
                    <div className={classes.mTablePagination}>
                      <MTablePagination {...props} />
                    </div>
                  </div>
                )
              },
            }}
          />
        </section>
      )}
    </>
  )
}
