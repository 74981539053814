import React, { ReactElement } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router' // included with gatsby

import { AppComponent } from '../../components/app/app.component'
import UserDetailComponent from '../../components/users/user-detail.component'
import LoginGuardComponent from '../../components/app/login.gaurd.component'

export default function UserDetail(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <Router>
          <UserDetailComponent path="/users/:id" />
        </Router>
      </LoginGuardComponent>
    </AppComponent>
  )
}
