import React, { ReactElement, useEffect, useState, useContext } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router' // imported with Gatsby
import { makeStyles, CircularProgress, TextField, Button, Tooltip } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { navigate } from 'gatsby'


import { AppUser } from '../../models/app_user'
import { getUser } from '../../services/user.service'
import UserPaymentsTable from './user-payments-table.component'
import { Payment } from '../../models/payment'
import { emailUsers } from '../../services/notification.service'
import { MessageContext } from '../../contexts/message.store'

export type UserDetailProps = {
  id?: string
} & RouteComponentProps


const useStyles = makeStyles((theme) => ({
  progressInvisible: {
    visibility: 'hidden',
  },
  componentWrapper: {
    display: 'flex',
    width: '100%',
  },
  detailWrapper: {
    width: '100%',
  },
  backArrow: {
    marginTop: '5px',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  fieldWrappers: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    paddingRight: theme.spacing(2),
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    marginBottom: '20px',
  },
  emailButton: {
    height: '50px',
  },
}))
export default function UserDetailComponent({ id }: UserDetailProps): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [user, setUser] = useState<AppUser>()
  const [payments, setPayments] = useState<Payment[]>()
  const getUserFromApi = async (): Promise<void> => {
    try {
      const apiUser = await getUser(+id)
      setUser(apiUser)
      setPayments(apiUser.payments)
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: err.status })
    }
  }
  useEffect(() => {
    getUserFromApi()
  }, [])

  const goBack = (): void => {
    navigate('/users')
  }

  const sendEmail = async (): Promise<void> => {
    try {
      await emailUsers([user.email])
      messageDispatch({ type: 'SUCCESS', message: 'Email Sent Successfully' })
    } catch (err) {
      messageDispatch({ type: 'ERROR', message: 'Sending Email Failed. Try again later.' })
    }
  }

  const detail = (): ReactElement => {
    return (
      <div className={classes.detailWrapper}>
        <section className={classes.infoSection}>
          <div className={classes.fieldWrappers}>
            <TextField
              disabled
              className={classes.field}
              label="Name"
              value={user.name}
            />
            <TextField
              label="Email"
              className={classes.field}
              disabled
              value={user.email}
            />
          </div>
          <Tooltip title="Send email to sales rep to notify them their commissions have been updated" enterDelay={1000} placement="right">
            <Button color="primary" className={classes.emailButton} onClick={sendEmail}>Send Email</Button>
          </Tooltip>
        </section>
        <UserPaymentsTable payments={payments} userId={user.id} setPayments={(newPayments): void => setPayments([...newPayments])} />
      </div>
    )
  }

  return (
    <div className={classes.componentWrapper}>
      <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
      {user ? detail() : (
        <CircularProgress />
      )}
    </div>
  )
}
