import axios from '../utils/axios'
import { Paystub, Payment, UserPayments } from '../models/payment'
import { getDateRange, PaymentFilterData } from '../utils/time'

export const getPayments = async (paymentFilter: PaymentFilterData): Promise<UserPayments> => {
  const { start, end } = getDateRange(paymentFilter)
  let paymentsUrl = `payments?start=${start}&end=${end}`
  if (paymentFilter.user) paymentsUrl += `&app_user=${paymentFilter.user}`
  const res: ApiResponse = await axios.get(paymentsUrl)
  return res.data
}

export const getPaystub = async (date: string, userId: number): Promise<Paystub> => {
  const res: ApiResponse = await axios.get(`payments/${date}/${userId}`)
  return res.data
}


export const addPayment = async (payment: Payment): Promise<Payment> => {
  const res: ApiResponse = await axios.post('payments', payment)
  return res.data
}

export const updatePayment = async (id: number, payment: Payment): Promise<number> => {
  const res: ApiResponse = await axios.patch(`payments/${id}`, payment)
  return res.data
}

export const delPayment = async (id: number): Promise<number> => {
  const res: ApiResponse = await axios.delete(`payments/${id}`)
  return res.data
}

export const getLatestPaymentMonth = async (): Promise<number> => {
  const res: ApiResponse = await axios.get('payments/latest_payment_month')
  return res.data
}
