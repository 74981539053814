import axios from '../utils/axios'
import { AppUser } from '../models/app_user'


export const getUsers = async (): Promise<AppUser[]> => {
  const res = await axios.get('app_users')
  return res.data
}

export const getUser = async (userId: number): Promise<AppUser> => {
  const res = await axios.get(`app_users/${userId}`)
  return res.data
}
